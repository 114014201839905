import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAdminWallPapers, searchAdminWallPapers } from "./Services.tsx";
import { IMAGE_BASE_URL } from "../../Utils/Connection.tsx";
import Spinner from "../../Components/Spinner.tsx";
import ImageDetails from "./ImageDetails.tsx";

type Props = {};

const SEARCH_LIMIT = 10;
const SEARCH_PAGE = 1;

export type Image = {
  _id: string;
  image: string;
  size: 0;
  favoriteCount: 0;
  downloadedTimes: 0;
  setAsWallPaperCount: 0;
  sharedCount: 0;
  viewed: 0;
  tags: string[];
  categories: string[];
  createdAt: string;
  updatedAt: string;
  __v: 0;
};

export default function Images({}: Props) {
  const [images, setImages] = useState([]);
  const [queryOptions, setQueryOptions] = useState({
    page: 1,
    limit: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImageOptions, setSelectedImageOptions] = useState<any>({
    visible: false,
    image: null,
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();

  const navigateToUploadImage = () => {
    navigate("/add-images");
  };

  const getImgs = async () => {
    setLoading(true);
    const imgs = await getAdminWallPapers(
      queryOptions.page,
      queryOptions.limit
    );
    setImages(imgs);
    setLoading(false);
  };

  useEffect(() => {
    getImgs();
  }, [queryOptions, deleteModal]);

  const getOffsetFromPage = (page: number, limit: number) => {
    return page * limit - limit;
  };
  const searchImages = async (search: string) => {
    setLoading(true);
    const offset = getOffsetFromPage(queryOptions.page, queryOptions.limit);
    const imgs = await searchAdminWallPapers(
      offset,
      queryOptions.limit,
      search
    );
    setImages(imgs);
    setLoading(false);
  };

  useEffect(() => {
    // add a cool down to the search
    const timeout = setTimeout(() => {
      if (searchText === "") {
        getImgs();
        return;
      }
      searchImages(searchText);
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchText]);

  let numberOfPages = Math.ceil(images.length / queryOptions.limit);

  if (images.length === 0) {
    numberOfPages = 1;
  } else if (images.length < queryOptions.limit) {
    numberOfPages = 1;
    // check if the number is divisible by the limit
  } else if (images.length % queryOptions.limit === 0) {
    numberOfPages = queryOptions.page + 1;
  }

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{ fontFamily: "Inter, Noto Sans, sans-serif" }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <p className="text-white text-4xl font-black leading-tight tracking-[-0.033em] min-w-72">
                Images
              </p>
              <button
                onClick={navigateToUploadImage}
                className="flex max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 bg-[#293038] text-white gap-2 text-sm font-bold leading-normal tracking-[0.015em] min-w-0 px-2.5"
              >
                <div
                  className="text-white"
                  data-icon="Plus"
                  data-size="20px"
                  data-weight="regular"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M136,136V88a8,8,0,0,0-16,0v48H88a8,8,0,0,0,0,16h32v48a8,8,0,0,0,16,0V152h48a8,8,0,0,0,0-16Z"></path>
                  </svg>
                </div>
                <span className="truncate">Upload Images</span>
              </button>
            </div>

            <div className="px-4 py-3">
              <label className="flex flex-col min-w-40 h-12 w-full">
                <div className="flex w-full flex-1 items-stretch rounded-xl h-full">
                  <div
                    className="text-[#9dabb8] flex border-none bg-[#293038] items-center justify-center pl-4 rounded-l-xl border-r-0"
                    data-icon="MagnifyingGlass"
                    data-size="24px"
                    data-weight="regular"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                    </svg>
                  </div>
                  <input
                    placeholder="Search images by filename or label"
                    className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border-none bg-[#293038] focus:border-none h-full placeholder:text-[#9dabb8] px-4 rounded-l-none border-l-0 pl-2 text-base font-normal leading-normal"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </label>
            </div>
            {loading && <Spinner />}
            <div className="flex w-full grow bg-[#111418] @container p-4">
              <div className="w-full gap-1 overflow-hidden bg-[#111418] @[480px]:gap-2 aspect-[3/2] rounded-xl grid grid-cols-[2fr_1fr_1fr]">
                {images.map((item: Image) => (
                  <div
                    onClick={() => {
                      setSelectedImageOptions({
                        visible: true,
                        image: item,
                      });
                    }}
                    key={item?._id}
                    className="w-full bg-center bg-no-repeat bg-cover aspect-auto rounded-none"
                    style={{
                      backgroundImage: `url(${IMAGE_BASE_URL}${item?.image})`,
                    }}
                  ></div>
                ))}
                {images.length === 0 && (
                  <div className="flex w-full h-full items-center justify-center">
                    <p className="text-white text-lg font-normal leading-normal">
                      No images found
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center justify-center p-4">
              <a className="flex size-10 items-center justify-center">
                <div
                  className="text-white"
                  data-icon="CaretLeft"
                  data-size="18px"
                  data-weight="regular"
                  onClick={() => { setQueryOptions({ ...queryOptions, page: queryOptions.page - 1 }) }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                  </svg>
                </div>
              </a>

              {Array.from({ length: numberOfPages }).map(
                (_, i) => (
                  <div
                    key={i}
                    onClick={() =>
                      setQueryOptions({ ...queryOptions, page: i + 1 })
                    }
                    className={`text-sm font-normal leading-normal flex size-10 items-center justify-center text-white rounded-full ${
                      queryOptions.page === i + 1 ? "bg-[#293038]" : ""
                    }`}
                  >
                    {i + 1}
                  </div>
                )
              )}

              <a className="flex size-10 items-center justify-center">
                <div
                  className="text-white"
                  data-icon="CaretRight"
                  data-size="18px"
                  data-weight="regular"
                  onClick={() => { setQueryOptions({ ...queryOptions, page: queryOptions.page + 1 }) }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ImageDetails
        isModalOpen={selectedImageOptions.visible}
        image={selectedImageOptions.image}
        setIsModalOpen={(visible: boolean) =>
          setSelectedImageOptions({ ...selectedImageOptions, visible })
        }
      />
    </div>
  );
}
