import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IMAGE_BASE_URL } from "../../Utils/Connection.tsx";
import { getCollections } from "./Services.tsx";

type Props = {};

export type Collection = {
  _id: string;
  name: string;
  coverImage: string;
  categories: any;
  type: "weather" | "timeBased" | "dark_light" | "basic";
};

export default function Collections({}: Props) {
  const [collections, setCollections] = useState([]);
  const [queryOptions, setQueryOptions] = useState({
    page: 0,
    limit: 10,
  });

  const navigate = useNavigate();

  const navigateToAddCollection = () => {
    navigate("/add-collection");
  };

  useEffect(() => {
    const getColelc = async () => {
      const res = await getCollections(queryOptions.page, queryOptions.limit);
      console.log(res);
      setCollections(res);
    };
    getColelc();
  }, [queryOptions]);

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{ fontFamily: "Inter, Noto Sans, sans-serif" }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <p className="text-white text-4xl font-black leading-tight tracking-[-0.033em] min-w-72">
                Collections
              </p>
              <button
                onClick={navigateToAddCollection}
                className="flex max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 bg-[#293038] text-white gap-2 text-sm font-bold leading-normal tracking-[0.015em] min-w-0 px-2.5"
              >
                <div
                  className="text-white"
                  data-icon="Plus"
                  data-size="20px"
                  data-weight="regular"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M136,136V88a8,8,0,0,0-16,0v48H88a8,8,0,0,0,0,16h32v48a8,8,0,0,0,16,0V152h48a8,8,0,0,0,0-16Z"></path>
                  </svg>
                </div>
                <span className="truncate">Add Collection</span>
              </button>
            </div>

            <div className="px-4 py-3">
              <label className="flex flex-col min-w-40 h-12 w-full">
                <div className="flex w-full flex-1 items-stretch rounded-xl h-full">
                  <div
                    className="text-[#9dabb8] flex border-none bg-[#293038] items-center justify-center pl-4 rounded-l-xl border-r-0"
                    data-icon="MagnifyingGlass"
                    data-size="24px"
                    data-weight="regular"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                    </svg>
                  </div>
                  <input
                    placeholder="Search images by filename or label"
                    className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border-none bg-[#293038] focus:border-none h-full placeholder:text-[#9dabb8] px-4 rounded-l-none border-l-0 pl-2 text-base font-normal leading-normal"
                    value=""
                  />
                </div>
              </label>
            </div>
            <div className="flex w-full grow bg-[#111418] @container p-4">
              <div className="w-full gap-1 overflow-hidden bg-[#111418] @[480px]:gap-2 aspect-[3/2] rounded-xl grid grid-cols-[2fr_1fr_1fr]">
                {collections.map((item: Collection) => (
                  <div
                    key={item?._id}
                    className="flex flex-col gap-3 pb-3"
                    onClick={() => navigate(`/collection/${item._id}`)}
                  >
                    <div
                      key={item?._id}
                      className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl"
                      style={{
                        backgroundImage: `url(${IMAGE_BASE_URL}collectionImage/${item?.coverImage})`,
                      }}
                    ></div>
                    <span className="text-white text-base font-medium leading-normal">
                      {item?.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center p-4">
              <a className="flex size-10 items-center justify-center">
                <div
                  className="text-white"
                  data-icon="CaretLeft"
                  data-size="18px"
                  data-weight="regular"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                  </svg>
                </div>
              </a>
              {Array.from({ length: 5 }).map((_, i) => (
                <div
                  key={i}
                  onClick={() =>
                    setQueryOptions({ ...queryOptions, page: i + 1 })
                  }
                  className={`text-sm font-normal leading-normal flex size-10 items-center justify-center text-white rounded-full ${
                    queryOptions.page === i + 1 ? "bg-[#293038]" : ""
                  }`}
                >
                  {i + 1}
                </div>
              ))}

              <a className="flex size-10 items-center justify-center">
                <div
                  className="text-white"
                  data-icon="CaretRight"
                  data-size="18px"
                  data-weight="regular"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                  >
                    <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
