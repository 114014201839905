import { fetchApi } from "../../Utils/Connection.tsx"

export const getCollections = async (page: number, limit: number) => {
    const res = await fetchApi(`collections/${limit}/${page}`, "GET");
    return res
}

export const getCollectionInfo = async (_id: string) => {
    const res = await fetchApi(`collection/${_id}`, "GET");
    return res
}

export const deleteCollection = async (_id: string) => {
    const res = await fetchApi(`collection/${_id}`, "DELETE");
    return res
}