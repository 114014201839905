import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getCreators } from "./Services.tsx";
import moment from "moment";

type Creator = {
  _id: string;
  name: string;
  imagesCount: number;
  email: string;
  phone: string;
  address: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

const CreatorsPage = () => {
  const navigate = useNavigate();

  const [creators, setCreators] = useState([]);

  const fetchCreators = async () => {
    const cat = await getCreators();
    setCreators(cat);
  };

  useEffect(() => {
    fetchCreators();
  }, []);

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <p className="text-white tracking-light text-[32px] font-bold leading-tight min-w-72">
                Creators
              </p>
              <div className="flex px-4 py-3 justify-end">
              <button
                onClick={() => navigate("/add-creator")}
                className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#293038] text-white text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Add Creator</span>
              </button>
            </div>
            </div>
            <div className="px-4 py-3">
              <label className="flex flex-col min-w-40 h-12 w-full">
                <div className="flex w-full flex-1 items-stretch rounded-xl h-full">
                  <div className="text-[#9dabb8] flex border-none bg-[#293038] items-center justify-center pl-4 rounded-l-xl border-r-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                    >
                      <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                    </svg>
                  </div>
                  <input
                    placeholder="Search for a creator"
                    className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border-none bg-[#293038] focus:border-none h-full placeholder:text-[#9dabb8] px-4 rounded-l-none border-l-0 pl-2 text-base font-normal leading-normal"
                    value=""
                  />
                </div>
              </label>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 py-3">
              {creators.map((creator: Creator) => (
                <div
                  key={creator._id}
                  className="bg-[#293038] rounded-xl p-4 text-white"
                >
                  <h3 className="text-lg font-bold">{creator.name}</h3>
                  <p className="text-sm">{creator.imagesCount} images</p>
                  <a
                    href={`mailto:${creator.email}`}
                    className="text-sm text-blue-500 underline"
                  >
                    {creator.email}
                  </a>
                  <p className="text-sm">{creator.phone}</p>
                  <p className="text-sm">{creator.address}</p>
                  <p className="text-xs text-gray-400">
                    Created at:{" "}

                    {moment(creator.createdAt).format("lll")}
                  </p>
                  <p className="text-xs text-gray-400">
                    Updated at:{" "}
                    {moment(creator.updatedAt).format("lll")}
                  </p>
                </div>
              ))}
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatorsPage;
