import React, { useEffect, useState } from "react";
import AddCollectionItem from "./AddCollectionItem.tsx";
import { getCategories } from "../Categories/Services.tsx";
import { Category } from "../Categories/Categories.tsx";
import { fetchApi, fetchMedia } from "../../Utils/Connection.tsx";
import AddWeatherItem from "./AddWeatherItem.tsx";
import SuccessModal from "../../Components/SuccessModal.tsx";
import ErrorModal from "../../Components/ErrorModal.tsx";
import Spinner from "../../Components/Spinner.tsx";
import ProgressBar from "../../Components/ProgressBar.tsx";

const TYPES = ["weather", "timeBased", "dark_light", "basic"];

const AddCollections = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState<string>("");
  const [body, setBody] = useState({
    name: "",
    category: "",
    type: "",
    coverImage: "",
    slots: [],
  
  });
  const [coverImageFile, setCoverImageFile] = useState<File | null>(null); // To hold the cover image file

  const [weatherModalVisible, setWeatherModalVisible] = useState(false);

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const ref = React.createRef<HTMLInputElement>();

  useEffect(() => {
    fetchCat();
  }, []);

  const fetchCat = async () => {
    const cat = await getCategories();
    setCategories(cat);
  };
  const onSelectCategory = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);

    setCategory(selectedOptions.map((option: any) => option?.value).join(","));

  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCoverImageFile(e.target.files[0]); // Set the file for uploading
    }
  };

  const toggleModal = () => {
    if (body.type === "weather") {
      setWeatherModalVisible(!weatherModalVisible);
      return;
    }
    setModalVisible(!modalVisible);
  };

  const handleSubmit = async () => {
    if (!coverImageFile) {
      alert("Please select a cover image");
      return;
    }

    console.log(body);
    const formData = new FormData();
    formData.append("name", body.name);
    formData.append("categories", category.trim());
    formData.append("type", body.type);
    formData.append("coverImage", coverImageFile); // Add the cover image file
    formData.append("slots", JSON.stringify(body.slots)); // You may need to adjust this depending on the server

    try {
      const res = await fetchMedia("collection", formData, (event) => {
        const percentage = Math.round((event.loaded * 100) / event.total);
        setProgress(percentage);
      });

      if (res) {
        setSuccessModal(true);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);

    setProgress(0); // Reset progress after completion
  };

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{
        "--select-button-svg": `url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724px%27 height=%2724px%27 fill=%27rgb(157,171,184)%27 viewBox=%270 0 256 256%27%3e%3cpath d=%27M181.66,170.34a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-48-48a8,8,0,0,1,11.32-11.32L128,212.69l42.34-42.35A8,8,0,0,1,181.66,170.34Zm-96-84.68L128,43.31l42.34,42.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,85.66Z%27%3e%3c/path%3e%3c/svg%3e")`,
        fontFamily: `Inter, "Noto Sans", sans-serif`,
      }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="gap-1 px-6 flex flex-1 justify-center py-z">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <p className="text-white tracking-light text-[32px] font-bold leading-tight min-w-72">
                New Collection
              </p>
            </div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Collection name
                </p>
                <input
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                  value={body.name}
                  onChange={(e) => setBody({ ...body, name: e.target.value })}
                />
              </label>
            </div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Category
                </p>
                <select
                  onChange={onSelectCategory}
                  multiple
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-auto rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal appearance-none"
                  style={{
                    backgroundImage: "none",
                    height: "auto",
                    maxHeight: "200px",
                  }}
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories.map((cat: Category) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Type
                </p>
                <select
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14  placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                  value={body.type}
                  onChange={(e) => setBody({ ...body, type: e.target.value })}
                >
                  <option value="" disabled>
                    Select a type
                  </option>
                  {TYPES.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div className="flex px-4 py-3 justify-start">
              <input
                type="file"
                onChange={handleImageUpload}
                accept="image/*"
                className="hidden" // Hidden input for file selection
                id="cover-image-upload"
                ref={ref}
              />
              <label
                htmlFor="cover-image-upload"
                className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#293038] text-white text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Upload cover image</span>
              </label>
              {coverImageFile && (
                <img
                  src={URL.createObjectURL(coverImageFile)}
                  alt="Cover Image"
                  className="w-10 h-10 rounded-lg ml-4"
                />
              )}
            </div>
            <div className="flex px-4 py-3">
              <button
                onClick={toggleModal}
                className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Add item</span>
              </button>
            </div>
            <div className="flex px-4 py-3 justify-start">
              <button
                onClick={handleSubmit}
                className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Submit Collection</span>
              </button>
            </div>
            {loading && (
                    <>
                      <Spinner />
                      <ProgressBar progress={progress} />
                    </>
                  )}
            <h3 className="text-white text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">
              Items
            </h3>
            <div className="flex items-center gap-4 bg-[#111418] px-4 min-h-[72px] py-2 justify-between">
              {body?.slots.map((item) => (
                <div key={item.id} className="flex items-center gap-4">
                  <div
                    className="bg-center bg-no-repeat aspect-square bg-cover rounded-lg size-14"
                    style={{
                      backgroundImage: `url("https://cdn.usegalileo.ai/stability/77d9efc7-8312-4b08-ac23-6d84e9ecb6d8.png")`,
                    }}
                  ></div>
                  <div className="flex flex-col justify-center">
                    <p className="text-white text-base font-medium leading-normal line-clamp-1">
                      {item.name}
                    </p>
                    <p className="text-[#9dabb8] text-sm font-normal leading-normal line-clamp-2">
                      {item.startingHour} - {item.endingHour}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <AddCollectionItem
          isModalOpen={modalVisible}
          setIsModalOpen={toggleModal}
          onAddItem={(item) => {
            setBody({ ...body, slots: [...body.slots, item] });
          }}
        />
        <AddWeatherItem
          isModalOpen={weatherModalVisible}
          setIsModalOpen={toggleModal}
          onAddItem={(item) => {
            setBody({ ...body, slots: [...body.slots, item] });
          }}
        />
      </div>
      <SuccessModal
        title="Success"
        message="Collection created successfully"
        setVisible={() => setSuccessModal(false)}
        visible={successModal}
      />
      <ErrorModal
        title="Error"
        message="An error occured while creating the collection"
        setVisible={() => setError(false)}
        visible={error}
      />
    </div>
  );
};

export default AddCollections;
