import React, { useState } from "react";
import { fetchApi } from "../../Utils/Connection.tsx";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../Components/ErrorModal.tsx";
import Spinner from "../../Components/Spinner.tsx";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";

const LoginPage = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility toggle

  const onLogin = async () => {
    try {
      setLoading(true);
      const response = await fetchApi("admin/login", "POST", body);

      if (response?.token) {
        console.log(response.token);
        // Store the token in session storage
        sessionStorage.setItem("authToken", response.token);
        // Redirect to the home page
        navigate("/");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBody({
      ...body,
      [e.target.name]: e.target.value,
    });
  };

  // Handle key press to submit form on Enter key
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onLogin();
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{ backgroundColor: "#111418" }} // Using your main app background color
    >
      <div
        className="bg-white p-8 rounded-lg shadow-md w-full max-w-md"
        style={{ backgroundColor: "#243647" }} // Using your secondary app color
      >
        <header className="flex justify-center mb-8">
          {/* Replace this with your logo image */}
          <img src="/logo.png" alt="Logo" className="w-16 h-16" />
          <h2 className="text-2xl font-bold text-center text-white ml-2">
            WallTales
          </h2>
        </header>

        <h3 className="text-xl font-semibold text-center text-gray-200 mb-4">
          Log in to WallTales
        </h3>
        <form onKeyDown={handleKeyDown}>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-300">
              Username or email
            </label>
            <input
              type="text"
              placeholder="Username or email"
              name="username"
              value={body.username}
              onChange={onChange}
              className="mt-1 block w-full px-3 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-6 relative">
            <label className="block text-sm font-medium text-gray-300">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"} // Toggle password visibility
              placeholder="Password"
              name="password"
              value={body.password}
              onChange={onChange}
              className="mt-1 block w-full px-3 py-2 bg-gray-800 text-gray-100 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
            >
              {showPassword ? (
                <EyeSlashIcon className="w-5 text-gray-400" />
              ) : (
                <EyeIcon className="w-5 text-gray-400" />
              )}
            </div>
          </div>
         
        </form>

        <button
          type="submit"
          onClick={onLogin}
          className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition"
        >
          Log in
        </button>

        

        {loading && <Spinner />}
        <ErrorModal
          visible={error}
          setVisible={setError}
          title="Error"
          message="Invalid credentials. Please try again."
        />
      </div>
    </div>
  );
};

export default LoginPage;
