import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

export default function Header({}: Props) {
  const [menuOpen, setMenuOpen] = useState(false); // State for menu toggle
  const navigate = useNavigate();

  // Function to toggle the menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#293038] px-4 py-3 bg-[#111418] md:px-10">
      {/* Logo and Title */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2 text-white">
          <div className="size-4">
            <svg
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 4H17.3334V17.3334H30.6666V30.6666H44V44H4V4Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <h2 className="text-white text-base md:text-lg font-bold leading-tight tracking-[-0.015em]">
            WallTales
          </h2>
        </div>
      </div>

      {/* Main navigation for larger screens */}
      <nav className="hidden md:flex flex-row items-center gap-4 md:gap-9">
        {/* <a
          className="text-white text-sm font-medium leading-normal"
          onClick={() => navigate("/")}
        >
          Overview
        </a> */}
        <a
          className="text-white text-sm font-medium leading-normal"
          onClick={() => navigate("/images")}
        >
          Images
        </a>
        <a
          className="text-white text-sm font-medium leading-normal"
          onClick={() => navigate("/collections")}
        >
          Collections
        </a>
        <a
          className="text-white text-sm font-medium leading-normal"
          onClick={() => navigate("/categories")}
        >
          Categories
        </a>
        <a
          className="text-white text-sm font-medium leading-normal"
          onClick={() => navigate("/creators")}
        >
          Creators
        </a>
      </nav>

      {/* Menu icon for mobile/tablet */}
      <div className="md:hidden">
        <button
          onClick={toggleMenu}
          className="text-white focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
          </svg>
        </button>
      </div>

      {/* Full-screen modal navigation for mobile/tablet */}
      {menuOpen && (
        <div className="fixed inset-0 bg-[#111418] bg-opacity-95 z-50 flex flex-col items-center justify-center space-y-8">
          <button
            onClick={toggleMenu}
            className="absolute top-5 right-5 text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M208.49,191.51a8,8,0,0,1-11.32,0L128,122.34,58.83,191.51a8,8,0,0,1-11.32-11.32L116.69,111,47.51,41.83A8,8,0,0,1,58.83,30.49L128,99.66l69.17-69.17a8,8,0,0,1,11.32,11.32L139.31,111l69.17,69.17A8,8,0,0,1,208.49,191.51Z"></path>
            </svg>
          </button>
          <nav className="flex flex-col items-center gap-8">
            {/* <a
              className="text-white text-lg font-medium leading-normal"
              onClick={() => {
                navigate("/");
                setMenuOpen(false);
              }}
            >
              Overview
            </a> */}
            <a
              className="text-white text-lg font-medium leading-normal"
              onClick={() => {
                navigate("/images");
                setMenuOpen(false);
              }}
            >
              Images
            </a>
            <a
              className="text-white text-lg font-medium leading-normal"
              onClick={() => {
                navigate("/collections");
                setMenuOpen(false);
              }}
            >
              Collections
            </a>
            <a
              className="text-white text-lg font-medium leading-normal"
              onClick={() => {
                navigate("/categories");
                setMenuOpen(false);
              }}
            >
              Categories
            </a>
            <a
              className="text-white text-lg font-medium leading-normal"
              onClick={() => {
                navigate("/creators");
                setMenuOpen(false);
              }}
            >
              Creators
            </a>
          </nav>
        </div>
      )}

      {/* Action Buttons (hidden on mobile) */}
      <div className="hidden md:flex items-center gap-2">
        <button className="flex cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 bg-[#293038] text-white gap-2 text-sm font-bold leading-normal tracking-[0.015em] px-2.5">
          <div className="text-white" data-icon="Bell" data-size="20px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M221.8,175.94C216.25,166.38,208,139.33,208,104a80,80,0,1,0-160,0c0,35.34-8.26,62.38-13.81,71.94A16,16,0,0,0,48,200H88.81a40,40,0,0,0,78.38,0H208a16,16,0,0,0,13.8-24.06ZM128,216a24,24,0,0,1-22.62-16h45.24A24,24,0,0,1,128,216ZM48,184c7.7-13.24,16-43.92,16-80a64,64,0,1,1,128,0c0,36.05,8.28,66.73,16,80Z"></path>
            </svg>
          </div>
        </button>
        <button className="flex cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 bg-[#293038] text-white gap-2 text-sm font-bold leading-normal tracking-[0.015em] px-2.5">
          <div className="text-white" data-icon="List" data-size="20px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
            </svg>
          </div>
        </button>
      </div>
    </header>
  );
}
