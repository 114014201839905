import React, { useEffect, useRef, useState } from "react";
import "../../index.css";
import { BASE_URL, fetchApi } from "../../Utils/Connection.tsx";

const imgs = [
  {
    title: "Palm Tree Paradise",
    author: "WallTales",
    resolution: "1920x1080",
    url: "https://cdn.usegalileo.ai/sdxl10/d3ca1872-61a1-436a-bad9-ea84034a7772.png",
  },
  {
    title: "Sunset on the Beach",
    author: "WallTales",
    resolution: "1920x1080",
    url: "https://cdn.usegalileo.ai/sdxl10/a67a11b0-95f4-4ad8-a651-4c38b3cb0332.png",
  },
  {
    title: "Vibrant Palm Leaves",
    author: "WallTales",
    resolution: "1920x1080",
    url: "https://cdn.usegalileo.ai/sdxl10/cd036710-53ab-4bee-af8a-af3345fc8477.png",
  },
  {
    title: "Ocean Waves",
    author: "WallTales",
    resolution: "1920x1080",
    url: "https://cdn.usegalileo.ai/sdxl10/c6408195-973e-4174-8de5-85834e662655.png",
  },
  {
    title: "Sunny Palm Trees",
    author: "WallTales",
    resolution: "1920x1080",
    url: "https://cdn.usegalileo.ai/sdxl10/62b11941-95a3-4565-9f29-8514f5b65287.png",
  },
];

const HomePage = () => {
  const [images, setImages] = useState([]);

  // useEffect(() => {
  //   const getImages = async () => {
  //     const res = await fetchApi("wallpapers", "GET");

  //     setImages(res);
  //   };
  //   getImages();
  // }, []);

  // const fileInputRef = useRef(null);

  // const handleButtonClick = () => {
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (event) => {
  //   const files = event.target.files;
  //   if (files.length > 0) {
  //     const newImages = Array.from(files).map((file) => {
  //       return {
  //         file: file, // Keep the original file for upload
  //         title: file.name,
  //         url: URL.createObjectURL(file), // This is for display purposes
  //         author: "You",
  //         resolution: "Unknown",
  //       };
  //     });
  //     setImages([...images, ...newImages]);
  //     uploadImages(newImages); // Only upload the new images
  //   }
  // };

  // const uploadImages = async (newImages) => {
  //   const formData = new FormData();

  //   newImages.forEach((image) => {
  //     formData.append("images", image.file); // Append the actual File object
  //   });

  //   try {
  //     const response = await fetch(
  //       "http://localhost:5000/api/wallpapers/upload",
  //       {
  //         method: "POST",
  //         body: formData,
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("authToken"),
  //         },
  //       }
  //     );

  //     if (response.ok) {
  //       console.log("Images uploaded successfully");
  //     } else {
  //       console.error("Failed to upload images");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading images:", error);
  //   }
  // };

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden" style={{ fontFamily: 'Inter, Noto Sans, sans-serif' }}>
    <div className="layout-container flex h-full grow flex-col">
      <div className="gap-1 px-6 flex flex-1 justify-center py-5">
        
        <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
          <div className="flex flex-wrap justify-between gap-3 p-4">
            <div className="flex min-w-72 flex-col gap-3">
              <p className="text-white tracking-light text-[32px] font-bold leading-tight">Dashboard</p>
              <p className="text-[#9dabb8] text-sm font-normal leading-normal">Welcome back, WallTales</p>
            </div>
          </div>
          <h3 className="text-white text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Recent Images</h3>
          <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/f3ef64d6-25f3-4df8-a7e9-9b3ae2794eca.png")` }}></div>

              <span className="text-white text-sm font-medium leading-normal">Sunset on the Beach</span>

              <div className="flex items-center gap-2">
                <div className="text-white" data-icon="Heart" data-size="20px" data-weight="regular">
                  <span className="text-white text-sm font-medium leading-normal">Likes</span>
                  <span className="text-white text-sm font-medium leading-normal">1.2k</span>
                </div>
                <div className="text-white" data-icon="Heart" data-size="20px" data-weight="regular">
                  <span className="text-white text-sm font-medium leading-normal">Likes</span>
                  <span className="text-white text-sm font-medium leading-normal">1.2k</span>
                </div>
                
                </div>
                <div className="flex items-center gap-2">
                <div className="text-white" data-icon="Heart" data-size="20px" data-weight="regular">
                  <span className="text-white text-sm font-medium leading-normal">Likes</span>
                  <span className="text-white text-sm font-medium leading-normal">1.2k</span>
                </div>
                <div className="text-white" data-icon="Heart" data-size="20px" data-weight="regular">
                  <span className="text-white text-sm font-medium leading-normal">Likes</span>
                  <span className="text-white text-sm font-medium leading-normal">1.2k</span>
                </div>
                
                </div>

            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/676dc90a-2f59-4805-821a-cea4b51ff4c2.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/4a750ccc-92ba-4e45-8cc7-dea4172ac85e.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/27199633-280f-441c-a672-3d2dff7e326a.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/9b913c20-dcf7-44f6-83c6-ac4221912527.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/cb1a74a3-bb9a-4308-83a9-e9b7c654f2e0.png")` }}></div>
            </div>
          </div>
          <h3 className="text-white text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">Most Liked Images</h3>
          <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/19425684-9a00-4213-badf-f11a455aa92c.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/7c22b48b-b18f-48c8-9d11-e3af3ec7ea62.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/22723775-e284-4b34-95c8-dfcf653c9eb4.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/506e39c9-49df-4549-83d8-d62a0bf861ab.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/4227ebb9-6536-4fd5-af74-fb11a9c1a37c.png")` }}></div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="w-full bg-center bg-no-repeat aspect-square bg-cover rounded-xl" style={{ backgroundImage: `url("https://cdn.usegalileo.ai/stability/2000954d-1a8f-46dd-9253-c3d1414cdb60.png")` }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default HomePage;
