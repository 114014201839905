import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
  const session = sessionStorage.getItem('authToken'); // Check for session storage

  if (!session) {
    // Redirect to login if no session
    return <Navigate to="/login" replace />;
  }

  // Render the wrapped component if session exists
  return element;
};

export default ProtectedRoute;
