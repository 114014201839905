import { fetchApi } from "../../Utils/Connection.tsx"

export const getAdminWallPapers = async (page: number, limit: number) => {
    try {
    const query = `page=${page}&limit=${limit}`;
    const res = await fetchApi(`admin/wallpapers?${query}`, "GET");
    console.log(res);
    return res;
}
catch (error) {
    console.error(error);
    return [];
}
}

export const searchAdminWallPapers = async (
    page: number,
    limit: number,
    search: string
  ) => {
    try {
      const res = await fetchApi(`admin/wallpapers/search/${page}/${limit}`, "POST", {
        search,
      });
  
      return res;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  


