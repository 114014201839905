import { fetchApi } from "./Utils/Connection.tsx";

// /search/wallpapers/:search/:offset/:limit'
export const searchImages = async (
  text: string,
  offset: number,
  limit: number
) => {
  const res: any = await fetchApi(
    `search/wallpapers/${text}/${offset}/${limit}`,
    "GET"
  );

  return res;
};
