import React, { useEffect } from 'react';

const MyComponent = () => {
  useEffect(() => {
    // Dynamically load Tailwind CSS script
    const script = document.createElement('script');
    script.src = 'https://cdn.tailwindcss.com?plugins=forms,container-queries';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden" style={{ fontFamily: 'Inter, "Noto Sans", sans-serif' }}>
      <div className="layout-container flex h-full grow flex-col">
        <header className="flex items-center justify-between whitespace-nowrap border-b border-solid border-b-[#293038] px-10 py-3">
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-4 text-white">
              <div className="size-4">
                <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 4H17.3334V17.3334H30.6666V30.6666H44V44H4V4Z" fill="currentColor" />
                </svg>
              </div>
              <h2 className="text-white text-lg font-bold leading-tight tracking-[-0.015em]">WallTales</h2>
            </div>
            <div className="flex items-center gap-9">
              <a className="text-white text-sm font-medium leading-normal" href="#">Explore</a>
              <a className="text-white text-sm font-medium leading-normal" href="#">Create</a>
              <a className="text-white text-sm font-medium leading-normal" href="#">Feed</a>
            </div>
          </div>
          <div className="flex flex-1 justify-end gap-8">
            <label className="flex flex-col min-w-40 !h-10 max-w-64">
              <div className="flex w-full flex-1 items-stretch rounded-xl h-full">
                <div className="text-[#9dabb8] flex border-none bg-[#293038] items-center justify-center pl-4 rounded-l-xl border-r-0" data-icon="MagnifyingGlass" data-size="24px">
                  {/* Magnifying Glass Icon */}
                </div>
                <input
                  className="w-full h-full bg-[#293038] text-white rounded-r-xl pl-4"
                  type="text"
                  placeholder="Search..."
                />
              </div>
            </label>
          </div>
        </header>
        <main className="flex-grow">
          {/* Main content goes here */}
        </main>
        <footer className="bg-[#1e2329] text-white py-4 text-center">
          <p>Footer content goes here</p>
        </footer>
      </div>
    </div>
  );
};

export default MyComponent;
