import { fetchApi } from "../../Utils/Connection.tsx"

type response = {
    tags: any;
    generatedImages: any;
}

type body = {
    images: any
    tags: any
    creator: any
    categories: any
}

export const fetchPrompt = async (prompt: string) => {
    const res: response = await fetchApi("admin/wallpapers/generateImages", "POST", {prompt});
    return res
}

export const uploadGeneratedImages = async (body: body) => {
    const res = await fetchApi("admin/wallpapers/uploadGeneratedImages", "POST", body);
    return res
}